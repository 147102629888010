export const markerStyles = [
  {
    "id": "mapitems-circle",
    "type": "circle",
    'filter': ['all',
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['==', 'active', 'false']],
    "layout": {},
    "paint": {
      "circle-color": [
        "match",
        ["get", "user_color"],
        "is-grey",
        "hsl(115, 4%, 43%)",
        "is-danger",
        "hsl(348, 100%, 61%)",
        "is-success",
        "hsl(141, 71%, 48%)",
        "is-warning",
        "hsl(48, 100%, 67%)",
        "is-info",
        "hsl(204, 86%, 53%)",
        "is-purple",
        "hsl(268,86%,53%)",
        "hsl(0,0%,100%)",
      ],
      "circle-opacity": 0.7,
      "circle-radius": 18,
      "circle-stroke-color": [
        "match",
        ["get", "user_color"],
        "is-grey",
        "hsl(115, 4%, 43%)",
        "is-danger",
        "hsl(348, 100%, 61%)",
        "is-success",
        "hsl(141, 71%, 48%)",
        "is-warning",
        "hsl(48, 100%, 67%)",
        "is-info",
        "hsl(204, 86%, 53%)",
        "is-purple",
        "hsl(268,86%,53%)",
        "hsl(0,0%,100%)",
      ]
    }
  },
  {
    "id": "mapitems-circle-selected",
    "type": "circle",
    'filter': ['all',
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['==', 'active', 'true']],
    "layout": {},
    "paint": {
      "circle-color": [
        "match",
        ["get", "user_color"],
        "is-grey",
        "hsl(115, 4%, 43%)",
        "is-danger",
        "hsl(348, 100%, 61%)",
        "is-success",
        "hsl(141, 71%, 48%)",
        "is-warning",
        "hsl(48, 100%, 67%)",
        "is-info",
        "hsl(204, 86%, 53%)",
        "is-purple",
        "hsl(268,86%,53%)",
        "hsl(0,0%,100%)",
      ],
      "circle-opacity": 0.7,
      "circle-radius": 20,
      "circle-stroke-width": 3,
      "circle-stroke-opacity": 0.9,
      "circle-stroke-color": [
        "match",
        ["get", "user_color"],
        "is-grey",
        "hsl(115, 4%, 43%)",
        "is-danger",
        "hsl(348, 100%, 61%)",
        "is-success",
        "hsl(141, 71%, 48%)",
        "is-warning",
        "hsl(48, 100%, 67%)",
        "is-info",
        "hsl(204, 86%, 53%)",
        "is-purple",
        "hsl(268,86%,53%)",
        "hsl(0,0%,100%)",
      ]
    }
  },
  {
    "id": "symbols",
    "type": "symbol",
    "minzoom": 1,
    'filter': ['all',
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature']],
    "layout": {
      "text-field": ["get", "user_name"],
      "text-font": ["DIN Pro Medium", "Arial Unicode MS Regular"],
      "text-size": 12,
      "icon-image": ["get", "user_icon"],
      "text-padding": 10,
      "text-justify": "auto",
      "text-variable-anchor": [
        "top-right",
        "bottom-right",
        "right",
        "top-left",
        "bottom-left"
      ],
      "text-radial-offset": [
        "interpolate",
        ["linear"],
        ["zoom"],
        1,
        0.05,
        5,
        0.0625,
        10,
        0.088,
        22,
        0.1
      ]
    },
    "paint": {
      "text-color": "hsl(36, 0%, 1%)",
      "text-halo-color": "hsl(36, 0%, 100%)",
      "text-halo-width": 1.25,
      "text-halo-blur": 0.5
    }
  },
  {
    "id": "gl-draw-line-static",
    "type": "line",
    "filter": ["all", ["==", "$type", "LineString"], ["==", "mode", "static"]],
    "layout": {
      "line-cap": "round",
      "line-join": "round"
    },
    "paint": {
      "line-color": "#000",
      "line-width": 3
    }
  },
  // ACTIVE (being drawn)
  // line stroke
  {
    "id": "gl-draw-line",
    "type": "line",
    "filter": ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
    "layout": {
      "line-cap": "round",
      "line-join": "round"
    },
    "paint": {
      "line-color": "#D20C0C",
      "line-dasharray": [0.2, 2],
      "line-width": 2
    }
  },
  // vertex point halos
  {
    "id": "gl-draw-polygon-and-line-vertex-halo-active",
    "type": "circle",
    "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
    "paint": {
      "circle-radius": 5,
      "circle-color": "#FFF"
    }
  },
  // vertex points
  {
    "id": "gl-draw-polygon-and-line-vertex-active",
    "type": "circle",
    "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
    "paint": {
      "circle-radius": 3,
      "circle-color": "#D20C0C",
    }
  },
  // polygon mid points
  {
    'id': 'gl-draw-polygon-midpoint',
    'type': 'circle',
    'filter': ['all',
      ['==', '$type', 'Point'],
      ['==', 'meta', 'midpoint']],
    'paint': {
      'circle-radius': 3,
      'circle-color': '#fbb03b'
    },
  }

]
