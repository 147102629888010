import actions from './deplo.actions';
import getters from './deplo.getters';
import mutations from './deplo.mutations';

/* deplo.store.js */
// State object
const state = {

    deploMap: null,
    deploDraw: null,
    maplevels: [],
    currentLevel: 0,
    currentTool: null,
    currentCategory: null,
    currentProject: null,
    currentProjectName: null,

    selectedFeatures: [],
    waitForSelected: false,
    waitForChild: null,

    active: true,
    // state for MapItems
    mapitems: [],
    connections: [],

    mapitem_comments: {},

    // categories
    categories: [],

    // Loading states
    sidepanelLoading: false,
    

};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
