import Vue from "vue";

export default {
    loadMap(state, map) {
        state.deploMap = map
    },
    loadDraw(state, draw) {
        state.deploDraw = draw
    },
    setMapLevels(state, maplevels) {
        state.maplevels = maplevels
    },
    setCurrentProject(state, project_id) {
        state.currentProject = project_id
    },
    setCurrentProjectName(state, name) {
        state.currentProjectName = name
    },
    setCurrentTool(state, data) {
        console.log(data)
        state.deploDraw.changeMode(data.tool, data.options)
        state.currentTool = data.tool
    },
    setCurrentToolCategory(state, cat) {
        state.currentCategory = cat
    },
    setSelection(state, selection) {
        state.selectedFeatures = selection
    },
    modeChanged(state, tool) {
        state.currentTool = tool
    },
    setCurrentLevel(state, maplevel) {

        state.currentLevel = maplevel.level

        for (const layer of [
            'mapitems-circle.cold',
            'mapitems-circle.hot',
            'mapitems-circle-selected.hot',
            'symbols.cold',
            'symbols.hot',
        ]) {
            state.deploMap.setFilter(layer,
                ["all",
                    ["==", "$type", 'Point'],
                    ["==", "user_level", maplevel.level]
                ]
            );
        }

        for (const layer of [
            'gl-draw-line.hot',
            'gl-draw-line.cold',
        ]) {
            state.deploMap.setFilter(layer,
                ["all",
                    ["==", "$type", 'LineString'],
                    ["==", "user_level", maplevel.level]
                ]
            )
        }

        state.maplevels.forEach(e => {
            if (e.style_url) {
                const _lyrs = e.style_url.split(",")
                _lyrs.forEach((_url, _index) => {
                    if (state.deploMap.getLayer(`user_level_${maplevel.level}_${_index}`)) {
                        if (e.level === maplevel.level) {
                            state.deploMap.setLayoutProperty(`user_level_${e.level}_${_index}`, 'visibility', 'visible')
                        } else {
                            state.deploMap.setLayoutProperty(`user_level_${e.level}_${_index}`, 'visibility', 'none')
                        }
                    }
                })

            }
        })

    },

    setMapItems: (state, items) => (state.mapitems = items),

    setConnections: (state, connections) => (state.connections = connections),

    updateOrAddMapItem(state, mapitem) {
        let item = state.mapitems.find(it => it.id === mapitem.id)
        if (item) {
            Object.assign(item, mapitem)
            Vue.delete(item, 'comments');
            Vue.set(state.mapitem_comments, mapitem.id, mapitem.comments)
            if(state.deploDraw) {
                state.deploDraw.delete(mapitem.id)
                state.deploDraw.add(mapitem.geo)
                // Auto select feature
                state.deploDraw.changeMode('simple_select', {featureIds: [mapitem.id]})
            }

        } else {
            state.mapitems.unshift(mapitem) // Add new item to mapitems
        }
    },

    addCommentToMapItem(state, data) {

        if (!state.mapitem_comments[data.commentable_id]) {
            // First comment, create list
            Vue.set(state.mapitem_comments, data.commentable_id, [data])
        } else {
            state.mapitem_comments[data.commentable_id].push(data)
        }
    },

    deleteCommentFromMapItem(state, data) {
        // new !
        let index = state.mapitem_comments[data.commentable_id].findIndex(comment => comment.id === data.id)
        state.mapitem_comments[data.commentable_id].splice(index, 1)
    },

    deleteMapItem(state, id) {
        let index = state.mapitems.findIndex(item => item.id === id)
        state.mapitems.splice(index, 1)
        state.deploDraw.delete([id])
    },

    deleteConnection(state, id) {
        let index = state.connections.findIndex(item => item.id === id)
        state.connections.splice(index, 1)
        state.deploDraw.delete(['c_' + id])
    },

    updateOrAddConnection(state, connection) {
        let item = state.connections.find(it => it.id === connection.id)
        if (item) {
            Object.assign(item, connection)
            state.deploDraw.delete(`c_${connection.id}`)
            state.deploDraw.add(connection.geo)
            // Auto select feature
            state.deploDraw.changeMode('simple_select', {featureIds: ['c_' + connection.id]})
        } else {
            state.connections.unshift(connection)
            state.deploDraw.add(connection.geo)
        }
    },

    setSidePanelLoading: (state, value) => (state.sidepanelLoading = value),

    setCategories: (state, categories) => (state.categories = categories),

    setWaitingForSelection: (state, value) => (state.waitForSelected = value),

    setWaitingForChildCreation: (state, value) => (state.waitForChild = value),


}