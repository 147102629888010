import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as markers from "@/components/deplo/markerStyles";
import MapboxGLButtonControl  from "@/components/deplo/MapboxButtonControl";
import {MapboxStyleSwitcherControl} from "mapbox-gl-style-switcher";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import router from '../../../router'
import { ToastProgrammatic as Toast } from 'buefy'
import bbox from '@turf/bbox';


export default {

    loadMap({ commit, dispatch, state }, project_id) {
        commit("setCurrentProject", project_id)
        mapboxgl.accessToken = 'pk.eyJ1IjoibHVja2FzZGMiLCJhIjoiY2sxdWR6d3B5MDkwOTNpcGFuNXh3dGhzNSJ9.nBYgv6LPBGFGDx56p8mRQQ'
        var myMap = new mapboxgl.Map({
            container: 'map',
            center: [4.35, 50.95],
            zoom: 9,
            style: 'mapbox://styles/luckasdc/ckrp5lalu4v7r1bqu3hyqeymp',
            hash: true,
        })

        var Draw = new MapboxDraw({
            userProperties: true,
            displayControlsDefault: false,
            controls: {
                point: false,
                line_string: false,
                trash: false
            },
            styles: markers.markerStyles
        })

        myMap.addControl(Draw, 'top-right');
        myMap.addControl(new MapboxStyleSwitcherControl([
            {
                title: "Light",
                uri:"mapbox://styles/luckasdc/ckrp5lalu4v7r1bqu3hyqeymp"
            },
            {
                title: "Dark",
                uri:"mapbox://styles/luckasdc/ckrpc3ag851je1bqu30wi9w21"
            },
            {
                title: "Satellite",
                uri:"mapbox://styles/luckasdc/ckrpbqr14358017o0vvj6uwsy"
            }]
        ));
        myMap.addControl(new mapboxgl.NavigationControl());

        state.maplevels.forEach(maplevel => {
            myMap.addControl(
                new MapboxGLButtonControl({
                    className: `mdi mdi-numeric-${maplevel.level}`,
                    title: `${maplevel.name}`,
                    eventHandler: function () {
                        console.log("Level: " + maplevel.level)
                        commit("setCurrentLevel", maplevel)
                    }
                }),
                'top-right'
            )
        })

        commit('loadDraw', Draw)

        myMap.on('load', function() {

            // Fetch first devices
            dispatch("fetchAllMapItemsGeo", project_id).then(response => {
                Draw.add(response.data)
                if(response.data.features.length > 0) {
                    myMap.fitBounds(bbox(response.data), { padding: 200, maxZoom: 19 });
                }
            })

            // Fetch connections
            dispatch("fetchAllConnectionsGeo", project_id).then(response => {
                Draw.add(response.data)
            })

            commit('setCurrentLevel', state.maplevels[0])

            myMap.on("styledata", (e) => {

                // Fetch the first symbol layer such that siteplans won't be drawn on top
                var layers = myMap.getStyle().layers;
                var firstSymbolId;
                for (var i = 0; i < layers.length; i++) {
                    if (layers[i].type === 'symbol') {
                        firstSymbolId = layers[i].id;
                        break;
                    }
                }

                state.maplevels.forEach(maplevel => {
                    if(maplevel.style_url) {
                        const _lyrs = maplevel.style_url.split(",")
                        _lyrs.forEach((_url, _index) => {
                            console.log(_url)
                            if(_url && !myMap.getLayer(`user_level_${maplevel.level}_${_index}`)) {
                                try {
                                    myMap.addLayer( {
                                            "id": `user_level_${maplevel.level}_${_index}`,
                                            "type": "raster",
                                            "source": {
                                                "url": _url,
                                                "type": "raster",
                                                "tileSize": 256
                                            },
                                            "minzoom": 14,
                                            "layout": {},
                                        },
                                        firstSymbolId
                                    )
                                } catch (err) {
                                    this.$buefy.toast.open({
                                        message: "Could not open raster url",
                                        type: 'is-danger'
                                    })
                                }

                            }
                        })
                    }
                })
            })

            // Add event functions
            myMap.on('draw.create', (e) => {
                console.log('creating device...')
                if(!state.currentCategory) {
                    Toast.open({
                        message: 'No category is selected while placing an object!',
                        type: 'is-danger',
                        queue: false
                    })
                    Draw.delete([e.features[0].id])
                    return
                }
                dispatch('panelLoading')
                dispatch('createMapItem',
                    {
                        point: JSON.stringify(e.features[0].geometry),
                        area_id: 1, // TODO
                        project_id: state.currentProject,
                        category_id: state.currentCategory,
                        status_id: 1, // TODO
                        parent_id: state.waitForChild ? state.waitForChild.id : null,
                        level: state.currentLevel
                    }
                ).then(resp => {
                    Draw.delete(e.features[0].id)
                    Draw.add(resp.data.data.geo)
                    commit("setSelection", [resp.data.data.geo])
                    commit("setWaitingForChildCreation", null)
                    router.push({ name: 'mapitems.detail', params: {id: resp.data.data.id, project_id: project_id}, replace: true }).catch(()=>{});
                    dispatch('panelLoadingComplete')
                }).catch(err => {
                    dispatch('panelLoadingComplete')
                    this.$buefy.toast.open({
                        message: "Something went wrong...",
                        type: 'is-danger'
                    })
                })
            })
            myMap.on('draw.update', (e) => {
                console.log('moving devices...')
                for (let i = 0; i < e.features.length; i++) {
                    if(e.features[i].geometry.type === "LineString") {
                        let updated_conn = {
                            id: e.features[i].properties.connection_id,
                            linestring: JSON.stringify(e.features[i].geometry)
                        }
                        dispatch('updateConnection', {formData: updated_conn, update: false}).then(response => {
                            Toast.open({
                                message: 'Connection changed',
                                type: 'is-success',
                                queue: false
                            })
                        })
                    }
                    else if (e.features[i].geometry.type === "Point"){
                        let updated_item = {
                            id: e.features[i].properties.mapitem_id,
                            point: JSON.stringify(e.features[i].geometry)
                        }
                        dispatch('updateMapItem', updated_item).then(response => {
                            Toast.open({
                                message: 'Item moved',
                                type: 'is-success',
                                queue: false
                            })
                        })
                    }
                }
            })

            myMap.on('draw.selectionchange', (e) => {
                if (!this.isCreating) {
                    console.log('selecting devices...')
                    this.isCreating = false
                    this.selected = e
                    commit("setSelection", e.features)
                    if(state.waitForSelected) {
                        console.log("Waiting for selected...")
                        // Theres a component waiting for a selection.
                        // No need to change routes or do something else, component will receive updates.
                        Draw.changeMode('simple_select')
                    }
                    else if(e.features.length === 1) {
                        var zoom = myMap.getZoom();
                        //myMap.easeTo({center: e.features[0].geometry.coordinates, zoom: zoom + 1, padding: -200})
                        if(e.features[0].properties.mapitem_id) {
                            router.push({ name: 'mapitems.detail', params: {id: e.features[0].properties.mapitem_id, project_id: project_id}, replace: true }).catch(()=>{});
                        }
                        else if(e.features[0].properties.connection_id) {
                            router.push({ name: 'connections.detail', params: {id: e.features[0].properties.connection_id, project_id: project_id}, replace: true }).catch(()=>{});
                        }
                    }
                    else if ( e.features.length > 1) {
                        // Multiple selected
                        router.push({ name: 'mapitems.overview', replace: true, params: { project_id: project_id }}).catch(()=>{});
                    }
                    else {
                        // No selection
                        router.push({ name: 'mapitems.overview', replace: true, params: { project_id: project_id }}).catch(()=>{});
                    }
                }
            })
            myMap.on('draw.modechange', (e) => {
                commit('modeChanged', e.mode)
            })

        });
        commit('loadMap', myMap)
    },

    loadDraw({ commit }) {

    },

    deleteItems({ commit, state, dispatch }, todelete) {
        for (let i = 0; i < todelete.length; i++) {

            if(todelete[i].geometry.type === "Point"){
                console.log("Deleteing itmems")
                dispatch('deleteMapItem', todelete[i].properties.mapitem_id).then(response => {
                    //commit('deleteMapItem', todelete[i].properties.mapitem_id)
                })
            }
            else if(todelete[i].geometry.type === "LineString"){
                console.log("Deleteing co,,necitons")
                dispatch('deleteConnection', todelete[i].properties.connection_id).then(response => {
                    //commit('deleteConnection', todelete[i].properties.connection_id)
                })
            }
        }
        router.push({name: 'mapitems.overview', replace: true, params: { project_id: state.currentProject }}).catch(()=>{});
    },


    panelLoading({ commit }) {
      commit('setSidePanelLoading', true);
    },
    panelLoadingComplete({ commit }) {
        commit('setSidePanelLoading', false);
    },

    fetchAllMapItems({ commit }, id) {
        this.$axios.get(`/api/projects/${id}/mapitems`).then(response => {
            commit('setMapItems', response.data.data);
        });
    },

    fetchAllConnections({ commit }, id) {
        this.$axios.get(`/api/projects/${id}/connections`).then(response => {
            commit('setConnections', response.data.data);
        });
    },

    fetchAllMapItemsGeo({ commit }, id) {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/projects/${id}/mapitems.geojson`).then(response => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        });
    },

    fetchAllConnectionsGeo({ commit }, id) {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/projects/${id}/connections.geojson`).then(response => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        });
    },

    fetchMapItem({ commit }, id ) {
        this.$axios.get(`/api/mapitems/${id}?include=comments,parent`).then(response => {
            commit('updateOrAddMapItem', response.data.data);
        });
    },

    fetchConnection({ commit }, id ) {
        this.$axios.get(`/api/connections/${id}?include=comments`).then(response => {
            commit('updateOrAddConnection', response.data.data);
        });
    },

    createMapItem({ commit, resolve }, data) {
        return new Promise((resolve, reject) => {
            this.$axios.post('/api/mapitems', data).then(response => {
                commit('updateOrAddMapItem', response.data.data);
                if(response.data.connectionUpdate) {
                    response.data.connectionUpdate.forEach(connection => {
                        commit("updateOrAddConnection", connection)
                    })
                }
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    updateMapItem({commit}, formData) {
        return new Promise((resolve, reject) => {
            this.$axios.patch(`/api/mapitems/${formData.id}`, formData)
                .then((response) => {
                    commit('updateOrAddMapItem', response.data.data)
                    if(response.data.connectionUpdate) {
                        response.data.connectionUpdate.forEach(connection => {
                            commit("updateOrAddConnection", connection)
                        })
                    }
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    updateConnection({commit}, params) {
        return new Promise((resolve, reject) => {
            this.$axios.patch(`/api/connections/${params.formData.id}`, params.formData)
                .then((response) => {
                        commit('updateOrAddConnection', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    resetConnection({dispatch}, selected) {
        let coordinates = selected.geometry.coordinates
        let formData = selected
        formData.geometry.coordinates = [coordinates[0], coordinates.slice(-1)[0]]
        formData.linestring = JSON.stringify(formData.geometry)
        formData.id = selected.properties.connection_id
        dispatch("updateConnection", {formData: formData, update: true})
    },

    deleteMapItem({commit}, id) {
        return new Promise((resolve, reject) => {
            this.$axios.delete(`/api/mapitems/${id}`)
                .then((response) => {
                    commit('deleteMapItem', response.data.data.id)
                    if(response.data.connectionDeleted) {
                        response.data.connectionDeleted.forEach(connection => {
                            commit("deleteConnection", connection)
                        })
                    }
                    resolve(response)
                })
                .catch((error) => {
                    reject(error.response.data.message)
                })
        })
    },

    deleteConnection({commit}, id) {
        return new Promise((resolve, reject) => {
            this.$axios.delete(`/api/connections/${id}`)
                .then((response) => {
                    console.log(response.data.data.id)
                    commit('deleteConnection', response.data.data.id)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error.response.data.message)
                })
        })
    },

    fetchAllCategories({ commit }) {
        this.$axios.get('/api/categories').then(response => {
            commit('setCategories', response.data.data);
        });
    },

    fetchCategory({ commit }, id) {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/categories/${id}`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error.response.data.message)
                })
        })
    },

    updateCategory({commit}, formData) {
        return new Promise((resolve, reject) => {
            this.$axios.patch(`/api/categories/${formData.id}`, formData)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error.response.data.message)
                })
        })
    },

    createCategory({ commit, resolve }, data) {
        return new Promise((resolve, reject) => {
            this.$axios.post('/api/categories', data).then(response => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    createComment({ commit, resolve }, data) {
        return new Promise((resolve, reject) => {
            this.$axios.post('/api/comments', data).then(response => {
                commit('addCommentToMapItem', response.data.data)
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    deleteComment({ commit, resolve }, id) {
        return new Promise((resolve, reject) => {
            this.$axios.delete(`/api/comments/${id}`).then(response => {
                commit('deleteCommentFromMapItem', response.data.data)
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    fetchMapLevels({ commit, resolve }, project_id) {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/projects/${project_id}/maplevels`).then(response => {
                commit('setMapLevels', response.data.data)
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    createMapLevel({ commit, resolve }, data) {
        return new Promise((resolve, reject) => {
            this.$axios.post(`/api/projects/${data.project_id}/maplevels`, data).then(response => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },

    deleteMapLevel({ commit, resolve }, data) {
        return new Promise((resolve, reject) => {
            this.$axios.delete(`/api/projects/${data.project_id}/maplevels/${data.id}`).then(response => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },




}