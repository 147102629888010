export default {
    getMapItem: (state) => (id) => {
        return state.mapitems.find(item => {
            return item.id === id
        })
    },

    getConnection: (state) => (id) => {
        return state.connections.find(conn => {
            return conn.id === id
        })
    },

    getCategory: (state) => (id) => {
        return state.categories.find(cat => {
            return cat.id === id
        })
    },

    commentsForMapItem: (state) => (id) => {
        return state.mapitem_comments[id]
    }

}